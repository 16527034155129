import React from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import AnimationLink from "../components/nav/AnimationLink"
import TransitionContext from "../context/TransitionContext"

export const TagRoute = ({ tag, posts, pageContext }) => {
  const postLinks = posts.map(post => (
    <li key={post.node.fields.slug}>
      <AnimationLink
        to={
          post.node.frontmatter.type === `works`
            ? `/works${post.node.frontmatter.slug}`
            : `/blog${post.node.fields.slug}`
        }
        ariaLabel={`Link to blog posts tagged with '${tag}'`}
        state={{ fromTagLink: true }}
      >
        <h2>
          {post.node.frontmatter.type === `works`
            ? post.node.frontmatter.clientName
            : post.node.frontmatter.title}
        </h2>
      </AnimationLink>
    </li>
  ))
  return (
    <section
      className="section"
      css={`
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Row align="center" justify="center">
        <Col xs="content">
          <ul className="taglist">{postLinks}</ul>
        </Col>
      </Row>
      <Row align="center" justify="center">
        <Col xs="content">
          <p>
            <AnimationLink
              ariaLabel={`Link to blog posts tagged with '${tag}'`}
              to={`/tags`}
              state={{ fromTagLink: true }}
              className="alltags"
            >
              Browse all tags
            </AnimationLink>
          </p>
        </Col>
      </Row>
    </section>
  )
}

const TagRouteComp = ({ location, pageContext, data }) => {
  const { tag } = pageContext

  const posts = data.allMdx.edges

  const { title } = data.site.siteMetadata
  const { totalCount } = data.allMdx
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? `` : `s`
  } tagged with “${tag}”`
  return (
    <>
      <SEO title={`${tag}`} pathname={location.pathname} />
      <TransitionContext location={location}>
        <Container
          fluid
          className="pagemargintop pagemarginbtm innerwrap"
          style={{ height: `100%` }}
        >
          <Row align="center" justify="center">
            <Col xs="content">
              <h3
                css={`
                  text-align: center;
                `}
              >
                {tagHeader}
              </h3>
            </Col>
          </Row>
          <TagRoute
            tag={tag}
            posts={posts}
            title={title}
            totalCount={totalCount}
            pageContext={pageContext}
          />
        </Container>
      </TransitionContext>
    </>
  )
}

export default TagRouteComp

export const pageQuery = graphql`
  query TagPage($tag: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 1000
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { eq: false } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            clientName
            slug
            type
          }
        }
      }
    }
  }
`
